document.addEventListener("DOMContentLoaded", () => {
    // !-----------------------------------------------------------------!
    // Открытие менюшки
    function showMenu(){
        const btn = document.querySelector(".burger_js"),
              menu = document.querySelector(".header_links_js"),
              closeBtn = document.querySelector(".close_js");
        btn.addEventListener("click", () => {
            menu.classList.add("-active");
        });
        closeBtn.addEventListener("click", () => {
            menu.classList.remove("-active");
        });
    }
    showMenu();
    // !-----------------------------------------------------------------!

    // ПЛАВНЫЙ СКРОЛЛ
    // собираем все якоря; устанавливаем время анимации и количество кадров
/*    const anchors = [].slice.call(document.querySelectorAll('a[href*="#"]'));

    anchors.forEach(function(item) {
        // каждому якорю присваиваем обработчик события
        item.addEventListener('click', function(e) {
            setTimeout(() => {
                window.scrollBy(0,-105);
            }, 10)
        });
    });*/

    // ------------------------ КОНЕЦ ТАБОВ ------------------------


    //    Выключить автофокус у фенсибокс
    Fancybox.bind("[data-fancybox]", {
        autoFocus:false,
        click: false,
        dragToClose: false
    });
    // !-----------------------------------------------------------------!


    // Ленивая загрузка изображений ДЛЯ ИЗОБРАЖЕНИЙ КОТОРЫЕ НЕ НАХОДЯТСЯ В СЛАЙДЕРЕ
    // Пишем class="lazy" + тег data-src="ссылка" для этих изображений, а src=""
    let lazyloadImages;
    if("IntersectionObserver" in window){
        lazyloadImages = document.querySelectorAll(".lazy");
        let imageObserver = new IntersectionObserver(function(entries, observer){
            entries.forEach(function(entry){
                if(entry.isIntersecting){
                    var image = entry.target;
                    image.src = image.dataset.src;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                }
            });
        });
        lazyloadImages.forEach(function(image){
            imageObserver.observe(image);
        });
    }else{
        let lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(".lazy");
        function lazyload(){
            if(lazyloadThrottleTimeout){
                clearTimeout(lazyloadThrottleTimeout);
            }
            lazyloadThrottleTimeout = setTimeout(function(){
                let scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function(img){
                    if(img.offsetTop < (window.innerHeight + scrollTop)){
                        img.src = img.dataset.src;
                        img.classList.remove("lazy");
                    }
                });
                if(lazyloadImages.length === 0){
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }
        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }

    // -------------- preventDefault --------------------
    function removeLink(){
        let a_arr = document.querySelectorAll(".remove_link");
        a_arr.forEach(elem => {
            elem.addEventListener("click", (e) => {
                e.preventDefault();
            });
        });
    }
    removeLink();
    // -------------- preventDefault END --------------------


});















